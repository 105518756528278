import './style/style.css'
import Functions from './components/Functions';
import { Footer } from './components/Footer';

function App() {
 

  return (
    <div className="App">
      <Functions />
      <Footer/>
    </div>
  );
}

export default App;
